.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected:before {
  background-color: #0EBFD6;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot {
  opacity: 0.4;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  width: 12px;
  height: 12px;
  background-color: lightslategrey;
}

.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: Oduda;
  src: url('./assets/fonts/Oduda-Bold.otf');
  font-weight: bold;
}
@font-face {
  font-family: Gotham;
  src: url('./assets/fonts/GothamLight.ttf');
  font-weight: 300;
}
@font-face {
  font-family: Gotham;
  src: url('./assets/fonts/GothamBook.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url('./assets/fonts/GothamMedium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url('./assets/fonts/GothamBold.ttf');
  font-weight: bold;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
